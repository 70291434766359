<template>
    <div class="flats-section" v-if="apartmentsArr != null" id="menu-link-flats">
        <div class="section-title section-title-black">
            <h2>{{apartmentsArr.section_title}}</h2>
        </div> 
        <div class="flats-section-content-in">
            <flatsSectionContent></flatsSectionContent>
            <CRMflats id="section-flats-main"></CRMflats>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    import flatsSectionContent from '@/components/flats/flats-section-content.vue'
    import CRMflats from '@/components/flats-crm/flats-crm-section.vue'

    export default {
        name: 'flats-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            apartmentsArr: 'loadApartments',
        }),
        components: {
            flatsSectionContent,
            CRMflats,
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats/flats-section.scss';
    @import '@/styles/components/flats/respond/flats-section-respond.scss';
</style>