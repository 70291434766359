<template>
    <div class="flats-crm-items-container flats-items-grid" v-if="flatsItems != null">
        <div class="flats-empty-msg" v-if="flatsEmptyMsg"><p>По данному запросу квартир в продаже не найдено</p></div>
        <div class="flats-items" v-if="!flatsEmptyMsg">
            <div class="flat-item" v-for="item in flatsItems" :key="item.id">
                <a href="#" @click.prevent="openCrmFlatModal(item)">
                    <div class="item-default item-img" v-if="item != null && item.id != null">
                        <div class="img-in" v-if="item.images.length > 0">
                            <img :src="'https://bazis-online.kz/crmc/rest/prices/getImage?imageId=' + pic.id" v-for="(pic, index) in item.images.filter(item => item.typeCode === '1')" :key="index">
                        </div>
                    </div>
                    <div class="item-desc">
                        <div class="item-info">
                            <ul>
                                <li>
                                    <div class="right">
                                        {{item.rooms}}
                                    </div>
                                    <div class="left">
                                        <span v-if="globalLang != 'kz/'">Комнат</span>
                                        <span v-else>Бөлмелер</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="right">
                                        {{item.area}} <span class="font-none">м²</span>
                                    </div>
                                    <div class="left">
                                        <span v-if="globalLang != 'kz/'">Площадь</span>
                                        <span v-else>Ауданы</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="right">
                                        {{item.floor}}
                                    </div>
                                     <div class="left">
                                        <span v-if="globalLang != 'kz/'">Этаж</span>
                                        <span v-else>Қабат</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="right">
                                        <span>№</span>{{item.number}}
                                    </div>
                                    <div class="left">
                                        <span v-if="globalLang != 'kz/'">Апартаменты</span>
                                        <span v-else>Пәтерлер</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'flats-item',
        components: {
        },
        data () {
            return {
                globalLang: window.GlobalLang, 
            }
        },
        methods: {
            getNumbersOfString(str) {
                return str.replace(/[^0-9]+/g, "");
            },
            getNumbersOfBlock(str) { 
                var parseStr = str.split(',')[0];
                return parseStr.replace(/[^0-9]+/g, "");
            },
            getNumber(n) {
                return String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },
            openCrmFlatModal(arr) {
                this.$store.commit('modalData', arr);
                setTimeout(() => {
                    // this.$modal.show('sales_land_flat_card_crm_modal');
                    this.$vfm.show('flats-crm-modal')
                }, 300);
            },
            startLoader() {
                this.$parent.$refs.topProgress.start();
            },
            openFormModal(param) {
                this.$store.commit('Open_Form_Modal', param);
            },
            getPlansImg(arr) {
                console.log(arr, 'TEST')
                return false;
            }
        },
        computed: mapGetters({
            flatsItems: 'loadFlatsItems',
            flatsEmptyMsg: 'loadFlatsEmptyMsg',
        }),
        beforeCreate() {
        },
        created () {
        },
        mounted () {
            // this.$store.watch(
            //     (state) => state.flatsPage,
            //     (newValue) => {
            //         if (newValue) {
            //            this.filterSize();
            //             this.filterFloor();
            //         }
            //     }
            // );
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-items.scss';
    @import '@/styles/components/flats-crm/flats-crm-items-main.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-items-respond.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-items-main-respond.scss';
    // @import '@/styles/components/flats-page/respond/flats-item-respond.scss';
</style>