import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import './config/gtm.js'
import './config/yandex.js'

import LoadScript from "vue-plugin-load-script";
import Maska from 'maska'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueNumber from 'vue-number-animation'
import VueObserveVisibility from 'vue3-observe-visibility'

import { vfmPlugin } from 'vue-final-modal'



window.axios = require('axios');
window.$ = window.jQuery = require('jquery');
var ionRangeSlider = require('ion-rangeslider');
import 'selectric/public/jquery.selectric.min.js'
// CSS LIBS
import 'ion-rangeslider/css/ion.rangeSlider.min.css'
import 'selectric/public/themes/plus/selectric.css'



window.body = $('body');
window.url = 'https://the-one.bazis.kz/cms/';
window.newsUrl = 'https://news.bazis.kz/cms/';
window.flatsUrl = 'https://flats.bazis.kz/cms/';
window.SalesURL = 'https://admin.sales.bazis.kz/';
window.CRMurl = 'https://bazis-online.kz/crmc';
window.JsonUrl = 'wp-json/acf/v3/';
window.host = 'the-one'; // dev var

window.GlobalLang = localStorage.getItem('languageTheOneSite');

if (window.GlobalLang === null) {
    window.GlobalLang = '';
    setDataLang(window.GlobalLang);
} else if (window.GlobalLang === 'ru') {
    window.GlobalLang = '';
    setDataLang(window.GlobalLang);
} else {
    setDataLang(window.GlobalLang);
}
  
function setDataLang() {
    document.querySelector('body').dataset.language = window.GlobalLang;
}

const app = createApp(App).use(store);
app.use(LoadScript)
app.use(Maska)
app.use(VueNumber)
app.use(VueObserveVisibility)
app.use(VueSmoothScroll, {
    updateHistory: false,
    duration: 700,
    offset: 0,
})
app.use(vfmPlugin({
    key: '$vfm',
    componentName: 'vue-modal',
    dynamicContainerName: 'vue-modal-container'
}))
app.mount('#app');