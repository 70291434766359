<template>
    <div class="location-panarams">
        <div class="section-title section-title-gray">
            <h2>{{locationArr.section_panarams.title}}</h2>
        </div>
        <div class="location-panarams-gallery">
            <div class="panarams-gallery">
                    <!-- :autoplay="swiperOptions.autoplay" -->

                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    effect="fade"
                    :slides-per-view="1"
                    :space-between="0"
                    :observer="true"
                    :observe-parents="true"
                    :loop="true"
                >
                    <swiper-slide v-for="(item, index) in locationArr.section_panarams.items" :key="index">
                        <div class="slide-pic">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="slide-content" v-if="item.desc != ''">
                            <div class="slide-desc">{{item.desc}}</div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="container container-controls">
                <div class="swiper-controls panarams-controls">
                    <div id="panaramsPref" class="panarams-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="panaramsNext" class="panarams-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
                <!-- <div class="swiper-pagination swiper-pagintaion-panarams" id="panaramsPagination"></div> -->
            </div>
            

        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    import { EffectFade, Autoplay, Navigation} from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	// Import Swiper styles
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';

    export default {
        name: 'location-panarams',
        data () {
            return {
                swiperOptions: {
                    navigation: {
                        nextEl: '#panaramsNext',
                        prevEl: '#panaramsPref'
                    },
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false
                    },
                },
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
        }),
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        setup() {
			return {
				EffectFade,
				modules: [Autoplay, EffectFade, Navigation],
			};
		},
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-panarams.scss';
    @import '@/styles/components/location/respond/location-panarams-respond.scss';
</style>