<template>
    <div class="inner-section" v-if="innerContentArr != null" id="menu-link-inner">
        <div class="section-title section-title-white">
            <h2>{{innerContentArr.section_title}}</h2>
        </div>
        <div class="inner-section-content">
            <innerFashion></innerFashion>
            <formSection class="location-concept" v-if="formSetUp != null && formSetUp.form_fashion_init == 'true'"></formSection>
            <innerOpenAir></innerOpenAir>
            <innerPlaySpace></innerPlaySpace>
            <innerLobbi></innerLobbi>
            <formSection></formSection>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import innerFashion from '@/components/inner-content/inner-fashion.vue'
    import innerOpenAir from '@/components/inner-content/inner-open-air.vue'
    import innerPlaySpace from '@/components/inner-content/inner-play-space.vue'
    import innerLobbi from '@/components/inner-content/inner-lobbi.vue'
    import formSection from '@/components/forms/form-section.vue'

    export default {
        name: 'inner-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            innerContentArr: 'loadInnerContent',
            formSetUp: 'loadFormSetUp',
        }),
        components: {
            innerFashion,
            innerOpenAir,
            innerPlaySpace,
            innerLobbi,
            formSection,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/inner-content/inner-section.scss';
    @import '@/styles/components/inner-content/respond/inner-section-respond.scss';
</style>