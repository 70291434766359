<template>
    <div class="btns-cards-view">
        <p>{{salesCardPage.flats_cards_info.view_title}}</p>
        <div class="btns">
            <div class="item" :class="{active: !gridView}">
                <a href="#" class="btn-view-list btn-change-cards-view" @click.prevent="setGridView(false)">
                    <svgIcon name="list" class="icon-list"/>
                </a>
            </div>
            <div class="item" :class="{active: gridView}">
                <a href="#" class="btn-view-grid btn-change-cards-view" @click.prevent="setGridView(true)">
                    <svgIcon name="grid" class="icon-grid"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    export default {
        name: 'sale-CRM-flats-btns',
        components: {
            svgIcon,
        },
        data () {
            return {
            }
        },
        computed: mapGetters({
            salesCardPage: 'loadSalesCardPage',
			gridView: 'loadLandFlatsGridView',
        }),
        methods: {
            setGridView(param) { 
				this.$store.commit('gridView', param);
            },
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-btns.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-btns-respond.scss';
    // @import '@/styles/respond/sales/sales-land-partials/land-flats-respond.scss';
    // @import '@/styles/respond/sales/respond-sale-item.scss';
</style>