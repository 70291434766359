<template>
    <div class="flats-crm-section active" :class="{'inViewCheck' : inViewCheck}" id="section-flats" v-if="pageSetup != null">
        <flatsFilterMobile v-if="isMobile"></flatsFilterMobile>
        <flatsTopBar v-if="isMobile" id="flatsTopBar"></flatsTopBar>
        <div class="container">
            <div class="land-section-title" v-if="salesCardPage != null">
                <h2>{{apartmentsArr.plans_title}}</h2>
                <flatsBtns v-if="!isMobile"></flatsBtns>
            </div>
            <flatsFilterDesctop v-if="!isMobile"></flatsFilterDesctop>
            <transition name="fade" mode="out-in">
                <flatsItemsGrid v-if="gridView"></flatsItemsGrid>
                <flatsItemsList v-else :mobileView="isMobile"></flatsItemsList>
            </transition>
        </div>
        <flatsModal :mobileView="isMobile"></flatsModal>

        <div class="pagination-btns"  v-if="flatsItems != null && flatsPagintaion.remainingApartments != 0">
            <a href="#" @click.prevent="getMoreFlats">
                <span v-if="globalLang != 'kz/'">Загрузить ещё</span> 
                <span v-else>Тағы жүктеу</span>
                {{flatsPagintaion.remainingApartments}}</a>
        </div>
        <formSection class="form-section-flats"></formSection>
    </div>   
</template>

<script>
    import {mapGetters} from 'vuex'
	import flatsFilterDesctop from '@/components/flats-crm/flats-crm-filter-desctop.vue'
	import flatsItemsGrid from '@/components/flats-crm/flats-crm-items.vue'
	import flatsItemsList from '@/components/flats-crm/flats-crm-items-list.vue'
	import flatsBtns from '@/components/flats-crm/flats-crm-btns.vue'
	import flatsModal from '@/components/flats-crm/flats-crm-modal.vue'
    // mobile
	import flatsTopBar from '@/components/flats-crm/flats-crm-topbar-mobile.vue'
	import flatsFilterMobile from '@/components/flats-crm/flats-crm-filter-mobile.vue'
    import formSection from '@/components/forms/form-section.vue'


    export default {
        name: 'sale-land-flats',
        components: {
			flatsFilterDesctop,
            flatsItemsGrid,
            flatsItemsList,
            flatsBtns,
            flatsModal,
            flatsTopBar,
            flatsFilterMobile,
            formSection,
        },
        data () {
            return {
                isMobile: false,
                inViewCheck: false,
                slicky: false,
                topOffetBar: null,
                minusNum: 50,
                finalSum: 0,
                globalLang: window.GlobalLang, 
                // for flats filter
            }
        },
        computed: mapGetters({
            pageSetup: 'loadPageSetUp',
            apartmentsArr: 'loadApartments',
            salesCardPage: 'loadSalesCardPage',
            flatsFilterData: 'loadFlatsFilterData',
            flatsItems: 'loadFlatsItems',
            flatsPagintaion: 'loadFlatsPagination',
            paginPageNumber: 'loadPaginPageNumber',
			gridView: 'loadLandFlatsGridView',
        }),
        methods: {
            getMoreFlats() {
                const count = this.paginPageNumber + 1;
                this.$store.commit('UpdatePaginPageNumber', count);
                this.flatsFilterData.pageNumber = this.paginPageNumber;
                this.$store.dispatch('getFlatsByPagination', this.flatsFilterData);
                this.$store.dispatch('getFlatsPagintationCounters', this.flatsFilterData);
            },
            slickyTopBar() {
                if (this.isMobile == true && this.topOffetBar != null) {
                    // console.log(window.scrollY);
                    this.minusNum = 100;
                    if (this.topOffetBar < 7257) {
                        var sum = 7257 - this.topOffetBar;
                        this.finalSum = this.topOffetBar + sum - this.minusNum;
                    } else {
                        this.finalSum = this.topOffetBar - this.minusNum;
                    }
                    // console.log(this.topOffetBar, this.minusNum)
                    if (window.scrollY >= this.finalSum) {
                        this.slicky = true;
                        // console.log(this.topOffetBar)
                    } else {
                        this.slicky = false;
                    }
                }
            },
            isMobileCheck() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                }
            },
            handleResize() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                } else {
                    this.isMobile = false
                }
            },
            checkResize() {
                window.addEventListener('resize', this.handleResize)
                this.handleResize();
            },
        },
        beforeCreate() {
        },
        created () {
            window.addEventListener('scroll', this.slickyTopBar);
        },
        unmounted() {
            window.removeEventListener('scroll', this.slickyTopBar);
        },
        mounted () {
            this.isMobileCheck();
            this.checkResize();
            this.$store.dispatch('getFlatsItems', {
                "complexCodes": ['ONE'],
                "linesOnPage": 3
                // "sortedByField": 'ROOM',
                // "sortedByDirection": 'ASCENDING',
            }).then((response) => {
                this.$store.dispatch('getFlatsPagintationCounters', {
                    "complexCodes": ['ONE'],
                    "linesOnPage": 3
                });
                // if (this.isMobile == true) {
                //     this.topOffetBar = parseInt($('#flatsTopBar').offset().top);
                //     setTimeout(() => {
                //         this.inViewCheck = true;
                //     }, 300);
                //     this.slickyTopBar();
                // }
            }); 
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-section.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-section-respond.scss';
</style>