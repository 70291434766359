<template>
    <div class="location-map">
        <div class="container">
            <div class="column-container">
                <div class="col-left">
                    <div class="location-map-info">
                        <div class="title">
                            <h3 v-html="locationArr.section_location.title"></h3>
                        </div>
                        <div class="desc-html" v-html="locationArr.section_location.desc"></div>
                    </div>
                </div>
                <div class="col-right">
                    <div class="location-map-img" id="scrollContainerMapLocation">
                        <img :src="locationArr.section_location.map" alt="" v-if="globalLang != 'kz/'">
                        <img :src="locationArr.section_location.map_kz" alt="" v-else>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'location-map',
        data () {
            return {
                globalLang: window.GlobalLang, 
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        methods: {
            scrollLeftMapMobile() {
                setTimeout(() => {
                    document.getElementById('scrollContainerMapLocation').scrollLeft += 200;
                }, 300);
            }
        },
        mounted() {
            this.scrollLeftMapMobile();
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-map.scss';
    @import '@/styles/components/location/respond/location-map-respond.scss';
</style>