<template>
    <div class="home">
        <indexPromoSection></indexPromoSection>
        <locationSection></locationSection>
        <conceptSection></conceptSection>
        <innerSection></innerSection>
        <!-- <formSection></formSection> -->
        <flatsSection></flatsSection>
        <!-- <formSection></formSection> -->
        <newsSection></newsSection>
    </div>
</template>

<script>
import indexPromoSection from '@/components/index-promo/index-promo.vue'
import locationSection from '@/components/location/location-section.vue'
import conceptSection from '@/components/concept/concept-section.vue'
import innerSection from '@/components/inner-content/inner-section.vue'
import flatsSection from '@/components/flats/flats-section.vue'
import newsSection from '@/components/news/news.vue'
// import formSection from '@/components/forms/form-section.vue'

export default {
    name: 'HomeView',
    components: {
        indexPromoSection,
        locationSection,
        conceptSection,
        innerSection,
        flatsSection,
        newsSection,
        // formSection,
    },
    methods: {
    },
}
</script>

<style lang="scss">
    @import '@/styles/components/home.scss';
</style>

