<template>
    <div class="inner-fashion">
        <div class="container">
            <div class="section-grid-inline">
                <div class="section-grid-info">
                    <div class="title title-small title-black">
                        <h3 v-html="innerContentArr.section_fashion.title"></h3>
                    </div>
                    <div class="section-grid-desc-counetrs section-grid-images-desc-black" v-html="innerContentArr.section_fashion.desc_images"></div>
                    <!-- <div class="desc-html desc-html-black desc-html-fashion" v-html="innerContentArr.section_fashion.desc"></div> -->
                </div>
                <div class="section-images-grid section-images-grid-reverse">
                    <div class="images-items">
                        <div class="item" v-for="(item, index) in innerContentArr.section_fashion.images" :key="index">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
                <div class="section-grid-desc-counetrs section-grid-desc-counetrs-mobile section-grid-images-desc-black" v-html="innerContentArr.section_fashion.desc_images" v-if="isMobileG"></div>
            </div>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'inner-fashion',
        data () {
            return {
            }
        },
        computed: mapGetters({
            innerContentArr: 'loadInnerContent',
            isMobileG: 'loadisMobileGlobal',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/inner-content/inner-fashion.scss';
    @import '@/styles/components/inner-content/respond/inner-fashion-respond.scss';
</style>