<template>
    <div class="concept-section" v-if="conceptArr != null" id="menu-link-concept">
        <div class="section-title section-title-black">
            <h2>{{conceptArr.section_title}}</h2>
        </div>
        <div class="concept-section-content">
            <conceptPrivate></conceptPrivate>
            <formSection class="location-concept" v-if="formSetUp != null && formSetUp.form_private_init == 'true'"></formSection>
            <conceptSafe></conceptSafe>
            <formSection class="location-concept" v-if="formSetUp != null && formSetUp.form_safe_init == 'true'"></formSection>
            <conceptDetails></conceptDetails>
            <formSection class="location-concept" v-if="formSetUp != null && formSetUp.form_accent_init == 'true'"></formSection>
            <conceptArch></conceptArch>
            <formSection class="location-concept" v-if="formSetUp != null && formSetUp.form_arch_init == 'true'"></formSection>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import conceptPrivate from '@/components/concept/concept-private.vue'
    import conceptSafe from '@/components/concept/concept-safe.vue'
    import conceptDetails from '@/components/concept/concept-details.vue'
    import conceptArch from '@/components/concept/concept-arch.vue'
    import formSection from '@/components/forms/form-section.vue'
    export default {
        name: 'concept-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
            formSetUp: 'loadFormSetUp',
        }),
        components: {
            conceptPrivate,
            conceptSafe,
            conceptDetails,
            conceptArch,
            formSection,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-section.scss';
    @import '@/styles/components/concept/respond/concept-section-respond.scss';
</style>