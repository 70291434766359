<template>
    <div class="secret-btns">
        <a href="#wrapper-content" v-smooth-scroll class="top-btn">
            <div class="in"><svgIcon name="arrow-top" class="icon-arrow-top"/></div>
            <small>вверх</small>
        </a>
    </div>  
</template>


<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    export default {
        name: 'secret-btns',
        components: {
            svgIcon,
        },
        data () {
            return {
                
            }
        },
    }
</script>