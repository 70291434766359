<template>
    <!-- SCRIPTS -->
    <noscript v-if="gtmNoscript != null"><iframe :src="'https://www.googletagmanager.com/ns.html?id=GTM-' + gtmNoscript" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <noscript v-if="yandexNoScript != null"><div><img :src="'https://mc.yandex.ru/watch/' + yandexNoScript" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
    <!-- END SCRIPTS -->
    <div class="wrapper" id="wrapper-content">
        <div class="wrapper-in">
            <formLandModal v-if="isOpenFormLandModal"></formLandModal>
            <AppHeader></AppHeader>
            <fixedHeader></fixedHeader>
            <main class="content" id="content-layout">
                <home></home>
            </main>
            <AppFooter :class="{openSidebar: openSendwich}"></AppFooter>
            <burgerMenu v-if="burgerMenuInit"></burgerMenu>
            <topBtn :class="{fadeIn: fadeInVisible}"></topBtn>
            <transition name="fade" mode="out-in">
                <div class="body-overlay" id="body-overlay" @click="closeModalForm()" v-if="isOpenFormLandModal"></div>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import home from '@/views/home.vue'
import AppHeader from '@/components/header/header.vue'
import AppFooter from '@/components/footer/footer.vue'
import burgerMenu from '@/components/menu/menu.vue'
import fixedHeader from '@/components/header/header-fixed.vue'
import formLandModal from '@/components/forms/form-land-modal.vue'
import topBtn from '@/components/partials/topBtn.vue'
import cookie from 'vue-cookie'


export default {
    name: 'app',
    components: {
        home,
        AppHeader,
        AppFooter,
        burgerMenu,
        fixedHeader,
        formLandModal,
        topBtn,
    },
    data () {
        return {
            iSmobile: false,
            burgerMenuInit: false,
            gtmNoscript: null,
            yandexNoScript: null,
            jivositeScript: null,
            fadeInVisible: false,
        }
    },
    computed: mapGetters({
        openSendwich: 'loadOpenSendwich',
        formSetUp: 'loadFormSetUp',
        isOpenFormLandModal: 'loadIsOpenFormLandModal',
    }),
    methods: {
        closeModalForm() {
            // this.$store.commit('Open_Sendwich', false);
            // document.getElementsByTagName('body')[0].classList.remove('overlay-open');
            if (this.isOpenFormLandModal) {
                cookie.set('theone_form_land_modal_timer', true, { expires: this.formSetUp.form_drop_modal.minutes + 'm' });
                this.$store.commit('Open_FormLandModal', false);
            }
        },
        initBurgerMenu() {
            this.burgerMenuInit = true
        },
        setNonScriptToBody() {
            this.yandexNoScript = '92272651';
            this.gtmNoscript = '5H2L8DG';
            this.jivositeScript = "8mknV5h9KV"; 
            this.addJivoToTemplate(this.jivositeScript);
        },
        addJivoToTemplate(key) {
            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', '//code.jivosite.com/widget/' + key)
            document.body.prepend(externalScript)
        },
        checkFormLandModal() {
            if (this.formSetUp != null) {
                if (this.formSetUp.form_drop_modal != undefined && this.formSetUp.form_drop_modal.init == 'true') {
                    // console.log(this.salesCard.project_shablon_fields, 'asdasdasdsa')
                    const seconds = parseInt(this.formSetUp.form_drop_modal.seconds) * 1000;
                    var timerCockie = cookie.get('theone_form_land_modal_timer');
                    setTimeout(() => {
                        if (timerCockie == null) {
                            this.$store.commit('Open_FormLandModal', true);
                        } 
                        // else { // если кука есть какие то действия при необходимости
                        //     console.log(timerCockie, 'COCKIE TIMER');
                        // }
                    }, seconds);
                } 
                // else {
                //     // console.log('FORM LAND MODAL IS FFFFFALLLLLLSEEEEE ')
                // }
            }
        },
        handleScroll () {
            if (window.scrollY >= window.innerHeight * 3 - 200) {
                this.fadeInVisible = true;
            } else {
                this.fadeInVisible = false;
            }
        },
        isMobileCheck() {
            if (window.innerWidth < 900) {
                this.$store.commit('isMobileGlobalSet', true)
            } else {
                this.$store.commit('isMobileGlobalSet', false)
            }
        },
        handleResize() {
            if (window.innerWidth < 900) {
                this.$store.commit('isMobileGlobalSet', true)
            } else {
                this.$store.commit('isMobileGlobalSet', false)
            }
        },
    },
    beforeCreate() {
        if (localStorage.getItem('languageTheOneSite') === null) {
            localStorage.setItem('languageTheOneSite', 'ru');
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize)
        this.setNonScriptToBody();
        this.$store.dispatch('getCrmAuthToken').then((response) => {
            this.$store.dispatch('getSalesCardPageData');
            this.$store.dispatch('getSectionsData').then((resolve) => {
                setTimeout(() => {
                    this.initBurgerMenu();
                }, 1000);
            }); 
        });
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize)
    },
    mounted () {
        this.$store.watch(
            (state) => state.pageSetup,
            (newValue) => {
                if (newValue)
                this.handleScroll();
                this.isMobileCheck();
            }
        ),
        this.$store.watch(
            (state) => state.formSetup,
            (newValue) => {
                if (newValue != null) {
                    this.checkFormLandModal();
                }
            }
        )
    }
}
</script>

<style>
.grecaptcha-badge {
    opacity: 0 !important;
    visibility: hidden;
}
</style>