<template>
    <div class="news-modal-content-inner">
        <button class="btn-close-news-modal" @click="closeNewsModal()"><svgIcon name="close" class="icon-close"/></button>
        <div class="col-left" v-if="visible">
            <div class="news-modal-top-titles">
                <h3>{{news.news_modal_title}}</h3>
            </div>
            <div class="news-modal-info-selection-item">
                <select id="select-month">
                    <option :value="index" v-for="(item, index) in newsData" :key="index">
                        {{item.news_modal_month}}
                    </option>
                </select>
                <svgIcon name="calendar" class="icon-calendar"/>
                <div class="icon-elem">
                    <svgIcon name="down-arrow" class="icon-down-arrow"/>
                </div>
            </div>
            <div class="news-modal-info-list">
                <ul>
                    <li v-for="(item, index) in newsData[valueOption].news_modal_list_info" :key="index">
                        <p>{{item.desc}}</p>
                    </li>
                </ul>
            </div>
            <div class="news-modal-info-desc" >
                <p>{{newsData[valueOption].news_modal_list_desc}}</p>
            </div>
        </div>
        <div class="col-right" v-if="visibleCarousel">
            <div class="news-info-modal-carousel">
                <swiper 
                    :modules="modules" 
                    :navigation="swiperOptions.navigation"
                    effect="fade"
                    :slides-per-view="1"
                    :space-between="0"
                    :observer="true"
                    :observe-parents="true"
                    :loop="false"
                >
                    <swiper-slide 
                        v-for="(slide, index) in newsData[valueOption].news_modal_gallery" :key="index"
                        :style="'background-image:url(' + slide.url + ')'"
                    >
                    </swiper-slide>
                </swiper>

                <div class="swiper-controls newsModal-controls" v-if="newsData[valueOption].news_modal_gallery.length > 1">
                    <div id="newsModalPref" class="newsModal-prev-slide slider-btn">
                        <svgIcon name="arrow-left" />
                    </div>
                    <div id="newsModalNext" class="newsModal-next-slide slider-btn">
                        <svgIcon name="arrow-right" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import {mapGetters} from 'vuex'
    import { EffectFade, Navigation} from 'swiper';
	import { Swiper, SwiperSlide } from 'swiper/vue';
	// Import Swiper styles
    import 'swiper/scss/pagination';
	import 'swiper/scss/effect-fade';
	import 'swiper/scss/autoplay';
	import 'swiper/scss';
    export default {
        name: 'NewsModal',
        props: ['valueOption', 'visible', 'visibleCarousel'],
        components: {
        },
        data () {
            return {
                swiperOptions: {
                    navigation: {
                        prevEl: '.newsModal-prev-slide',
                        nextEl: '.newsModal-next-slide'
                    },
                },
            }
        },
        components: {
            Swiper,
			SwiperSlide,
			svgIcon,
        },
        computed: mapGetters({
            news: 'loadNews',
            newsData: 'loadNewsData',
        }),
        setup() {
			return {
				EffectFade,
				modules: [EffectFade, Navigation],
			};
		},
        methods: {
            closeNewsModal() {
                this.$vfm.hide('news-modal');
            },
        },
        mounted () {
        }
    } 
</script>

<style lang="scss" scoped>
    @import '@/styles/components/news/news-modal.scss';
    @import '@/styles/components/news/respond/news-modal-respond.scss';
</style>