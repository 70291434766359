<template>
    <div class="form-container">
        <!-- <h3 class="form-title" v-if="formVisible">{{formSetup.form_title}}</h3> -->
        <Form class="form-contacts" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" v-if="formVisible">
            <div class="form-in">
                <TextInput
                    name="name"
                    type="text"
                    :placeholder="formSetUp.inputname"
                    :class="{'error' : errors.name}"
                />
                <TextInput
                    name="phone"
                    type="text"
                    :placeholder="formSetUp.inputphone"
                    v-maska="'+7 (###) ###-##-##'"
                    :class="{'error' : errors.phone}"
                />
                <VueRecaptcha
                    ref="recaptchaFormPage"
                    @verify="onCaptchaVerified"
                    :sitekey="gCap_siteKey"
                    size="invisible"
                />
                <div class="btn-send">
                    <button class="btn btn-submit btn-success" type="submit" :disabled="errors.name || errors.phone">{{formSetUp.btn_name}}</button>
                </div>
            </div>
            
        </Form>
        <div class="successMessage" v-if="!formVisible">
            <h3>{{formSetUp.form_success_msg}}</h3>
        </div>
        <div class="to-many-requests-msg" v-if="toManySendingMsg">
            <span v-if="globalLang == ''">Вы отправили слишком много заявок, попробуйте позднее</span>
            <span v-if="globalLang == 'kz/'">Сіз жіберді тым көп өтінімдерді көріңіз кешіктірмей</span>
            <span v-if="globalLang == 'en/'">You have sent too many requests, please try again later</span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { Form } from "vee-validate";
    import * as Yup from "yup";
    import { ref } from 'vue'
    import TextInput from "@/components/forms/TextInput.vue";
    import { VueRecaptcha } from 'vue-recaptcha';
    export default {
        name: 'FormFields',
        data () {
            return {
                globalLang: window.GlobalLang, 
                toManySendingMsg: false,
                phone: '',
                name: '',
                agree: false,
                errorMsg: false,
                formVisible: true,
            }
        },
        components: {
            TextInput,
            Form,
            VueRecaptcha,
        },
        computed: mapGetters({
            formSetUp: 'loadFormSetUp',
            gCap_siteKey: 'loadGcap_sitekey',
        }),
        setup() {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .required('Title is required')
                    .matches(/^([а-яА-ЯёЁa-zA-Z].*[\s\.]*){3,35}$/),
                phone: Yup.string()
                    .required('First Name is required')
                    .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10,10}$/),
            });
            return {
                schema,
            };
        },
        methods: {
            onCaptchaVerified(response) {
                let submissionCounter = localStorage.getItem('TheOne_submissionCounter') || 0;
                let lastSubmissionTime = localStorage.getItem('TheOne_lastSubmissionTime') || 0;
                var currentMinutes = this.getCurrentMinutes();
                if (currentMinutes - lastSubmissionTime >= 5) {
                    submissionCounter = 0;
                }
                if (submissionCounter >= 5) {
                    this.toManySendingMsg = true;
                    console.log('Превышен лимит отправок формы. Пожалуйста, подождите некоторое время.');
                    return;
                }
                // console.log(response)
                console.log('start sending verify is complete');
                this.formVisible = false;
                var data = {
                    'name': this.name,
                    'phone': this.phone,
                    'project' : 'The One',
                    'secret_key' : '0L3QtSDRgdGD0LnRgtC1INGB0LLQvtC5INC90L7RgQ==',
                };
                console.log(data);
                var querystring = require('querystring');
                axios.post('https://admin.sales.bazis.kz/server/form-call.php', querystring.stringify(data))
                .then(() => {
                    submissionCounter++;
                    localStorage.setItem('TheOne_submissionCounter', submissionCounter);
                    localStorage.setItem('TheOne_lastSubmissionTime', currentMinutes);
                    dataLayer.push({'event' : 'form_sent'})
                })
                .catch(error => {
                    console.error('Form Page submission error:', error);
                });
            },
            onSubmit(values) {
                this.name = values.name;
                this.phone = values.phone;
                this.$refs.recaptchaFormPage.execute();
            },
            getCurrentMinutes() {
                return Math.floor(new Date().getTime() / (1000 * 60));
            },
            // onSubmit(values) {
            //     this.formVisible = false;
            //     var querystring = require('querystring');
            //     var data = {
            //         'name': values.name,
            //         'phone': values.phone,
            //         'project' : 'The One',
            //         'secret_key' : '0L3QtSDRgdGD0LnRgtC1INGB0LLQvtC5INC90L7RgQ==',
            //     };
            //     // console.log('ASDASDASDASDAS', values);
            //     axios.post('https://sales.bazis.kz/server/form-call.php', querystring.stringify(data)).then(() => {
            //         dataLayer.push({'event' : 'form_sent'})
            //     });
            // }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/components/forms/form.scss';
    @import '@/styles/components/forms/respond/form-respond.scss';
    // @import '@/styles/components/forms/forms-respond.scss';
</style>
