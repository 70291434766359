<template>
    <div class="location-section" v-if="locationArr != null" id="menu-link-location">
        <div class="section-title section-title-white">
            <h2>{{locationArr.section_title}}</h2>
        </div>
        <div class="location-section-content">
            <locationMap></locationMap>
            <formSection class="location-form" v-if="formSetUp != null && formSetUp.form_location_init == 'true'"></formSection>
            <locationPanarams></locationPanarams>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    import locationMap from '@/components/location/location-map.vue'
    import locationPanarams from '@/components/location/location-panarams.vue'
    import formSection from '@/components/forms/form-section.vue'

    export default {
        name: 'location-section',
        data () {
            return {
            }
        },
        computed: mapGetters({
            locationArr: 'loadLocation',
            formSetUp: 'loadFormSetUp',
        }),
        components: {
            locationMap,
            locationPanarams,
            formSection,
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/location/location-section.scss';
    @import '@/styles/components/location/respond/location-section-respond.scss';
</style>