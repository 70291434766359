<template>
    <div class="flats-crm-filter">
        <flatsFilter></flatsFilter>
    </div>   
</template>

<script>
    import {mapGetters} from 'vuex'
	import flatsFilter from '@/components/flats-crm/flats-crm-filter.vue'
    export default {
        name: 'sale-CRM-flats-filter-desctop',
        components: {
            flatsFilter,
        },
        data () {
            return {
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-filter-desctop.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-filter-desctop-respond.scss';
</style>