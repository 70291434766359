<template>
    <div class="index-promo-section" v-if="pageSetup != null">
        <div class="promo-pic-desctop">
            <img :src="pageSetup.index_pic_main_desctop" alt="">
        </div>
        <div class="promo-pic-desctop-mobile">
            <img :src="pageSetup.index_pic_main_mobile" alt="">
        </div>
        <div class="page-title-mobile">
            <h1>
                {{pageSetup.index_page_title.desc_bold}}
                <span>{{pageSetup.index_page_title.desc_thin}}</span>
            </h1>
        </div>
        <div class="promo-hot-btns">
            <ul>
                <li>
                    <a href="#section-flats-main" v-smooth-scroll="{offset: offset}">{{pageSetup.btn_flat_route}}</a>
                </li>
                <li><a :href="pageSetup.btn_present_group.file" target="_blank">{{pageSetup.btn_present_group.title}}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'index-promo-section',
        data () {
            return {
                offset: 0,
                isMobile: false,
            }
        },
        computed: mapGetters({
            pageSetup: 'loadPageSetUp',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        
        methods: {
            isMobileCheck() {
                if (window.innerWidth < 767) {
                    this.isMobile = true
                    this.mobileOffset();
                }
            },
            mobileOffset() {
                this.offset = -55
            }
        },
        created() {
            this.isMobileCheck();
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/index-promo/index-promo.scss';
    @import '@/styles/components/index-promo/index-promo-respond.scss';
</style>