<template>
    <div class="flats-crm-topbar">
        <div class="col col-left">
            <div class="btns-cards-view-mobile">
                <div class="btns">
                    <div class="item" :class="{active: gridView}">
                        <a href="#" class="btn-view-grid btn-change-cards-view" @click.prevent="setGridView(true)">
                            <svgIcon name="grid" class="icon-grid"/>
                        </a>
                    </div>
                    <div class="item" :class="{active: !gridView}">
                        <a href="#" class="btn-view-list btn-change-cards-view" @click.prevent="setGridView(false)">
                            <svgIcon name="list" class="icon-list"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-right">
            <div class="filter-link">
                <a href="#" @click.prevent="openFilterWindow">
                    <svgIcon name="filter" class="icon-filter"/>
                    <span>Фильтр</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import svgIcon from '@/components/partials/SvgIcon.vue';

    export default {
        name: 'sale-CRM-flats-topbar',
        components: {
            svgIcon,
        },
        data () {
            return {
            }
        },
        computed: mapGetters({
            filterVisible: 'loadVisibleFilterMobile',
			gridView: 'loadLandFlatsGridView',
        }),
        methods: {
            setGridView(param) { 
				this.$store.commit('gridView', param);
            },
            openFilterWindow() {
                window.body.addClass('overflowTrue');
                document.getElementById('header').classList.add('notVisible');
				this.$store.commit('SetVisibleFilterMobile', true);
            }
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/respond/flats-crm-topbar.scss';
    // @import '@/styles/components/flats-crm/respond/flats-crm-btns-respond.scss';
    // @import '@/styles/respond/sales/sales-land-partials/land-flats-respond.scss';
    // @import '@/styles/respond/sales/respond-sale-item.scss';
</style>