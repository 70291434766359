<template>
    <div class="filter-items" v-if="filterData != null && salesCardPage != null">
        <div class="filter-item filter-room">
            <h3>{{salesCardPage.flats_cards_info.filter_crm_room}}</h3>
            <div class="filter room-items">
                <div class="item-R" v-for="(item, index) in pageSetup.filter_set_room" @click="e => e.target.classList.toggle('active')" :key="index">
                    <input type="checkbox" :id="'room_' + item.number" :value="item.number" v-model="filterData.roomQuantities">
                    <label :for="'room_' + item.number" @click="setUpDataClick" :class="{'active' : getActiveClass(item)}">{{item.number}}</label>
                </div>
            </div>
        </div>
        <div class="filter-item filter-floor">
            <h3>{{salesCardPage.flats_cards_info.filter_crm_floor}}</h3>
            <div class="filter" id="FloorFilter">
                <input type="text" id="floor-filter" name="floor"  class="ionRangeSlider" value="" />
            </div>
        </div>
        <div class="filter-item filter-size">
            <h3>{{salesCardPage.flats_cards_info.filter_crm_size}} <span class="font-none">м²</span></h3>
            <div class="filter" id="SizeFilter">
                <input type="text" id="size-filter" name="size"  class="ionRangeSlider" value="" />
            </div>
        </div>
        <div class="filter-item filter-btn-mobile">
            <a href="#" @click.prevent="closeFilterMobile">
                ПОКАЗАТЬ АПАРТАМЕНТЫ
            </a>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'sale-CRM-flats-filter',
        components: {
        },
        data () {
            return {
                filterData: null,
                FloorFilter: null,
                SizeFilter: null,
                PriceFilter: null,
            }
        },
        computed: mapGetters({
            pageSetup: 'loadPageSetUp',
            salesCardPage: 'loadSalesCardPage',
            paginPageNumber: 'loadPaginPageNumber',
            resetModeTrigger: 'loadResetTriggerMove',
        }),
        methods: {
            setUpDataClick() {
                // this.updateFilterDataBase();
                this.filterData.pageNumber = 1;
                setTimeout(() => {
                    this.getItemsFlatsByFilter();
                }, 300);
            },
            setFilterDataBase() {
                this.filterData = {
                    complexCodes: [this.pageSetup.crm_code],
                    roomQuantities: [],
                    areaFrom: this.pageSetup.filter_set_size.min,
                    areaTo: this.pageSetup.filter_set_size.max,
                    floorRangeStart: this.pageSetup.filter_set_floor.min,
                    floorRangeEnd: this.pageSetup.filter_set_floor.max,
                    meterPriceFrom: this.pageSetup.filter_set_money.min,
                    meterPriceTo: this.pageSetup.filter_set_money.max,
                    linesOnPage: 3,
                    pageNumber: 1,
                    // notFirstFloor: "false",
                };
                this.$store.commit('setFlatsFilterData', this.filterData);
            },
            getItemsFlatsByFilter() {
                // this.$store.dispatch('getFlatsByFilter', this.filterData);
                this.$store.commit('UpdatePaginPageNumber', 1);
                this.$store.commit('setFlatsFilterData', this.filterData);
                this.$store.dispatch('getFlatsByFilter', this.filterData);
                this.$store.dispatch('getFlatsPagintationCounters', this.filterData);
            },
            getActiveClass(elem) {
                // console.log(elem);
                var data = this.filterData.roomQuantities;
                var filter = data.filter(item => {
                    return item == elem.number
                });
                // console.log(filter);
                if (filter.length > 0)
                return true;
                
            },
            BuildIonRangeSlider() {
                var _self = this;
                _self.FloorFilter = $("#floor-filter");
                _self.SizeFilter = $('#size-filter');
                _self.PriceFilter = $('#price-filter');
                
                _self.FloorFilter.ionRangeSlider({
                    type: "double",
                    min: _self.pageSetup.filter_set_floor.min,
                    max: _self.pageSetup.filter_set_floor.max,
                    step: 1,
                    hide_min_max: true,
                    onChange: function (data) {
                        _self.filterData.floorRangeStart = data.from;
                        _self.filterData.floorRangeEnd = data.to;
                        _self.filterData.pageNumber = 1;
                        // _self.updateFilterDataBase();
                        // console.log(_self.filterData, 'TEST')
                        _self.getItemsFlatsByFilter();
                    },
                });
                _self.SizeFilter.ionRangeSlider({
                    type: "double",
                    min: _self.pageSetup.filter_set_size.min,
                    max: _self.pageSetup.filter_set_size.max,
                    step: 1,
                    hide_min_max: true,
                    onChange: function (data) {
                        _self.filterData.areaFrom = data.from;
                        _self.filterData.areaTo = data.to;
                        _self.filterData.pageNumber = 1;
                        // _self.updateFilterDataBase();
                        _self.getItemsFlatsByFilter();
                    },
                });
                _self.PriceFilter.ionRangeSlider({
                    type: "double",
                    min: _self.pageSetup.filter_set_money.min,
                    max: _self.pageSetup.filter_set_money.max,
                    step: 1000,
                    hide_min_max: true,
                    onChange: function (data) {
                        _self.filterData.meterPriceFrom = data.from;
                        _self.filterData.meterPriceTo = data.to;
                        _self.filterData.pageNumber = 1;
                        // _self.updateFilterDataBase();
                        _self.getItemsFlatsByFilter();
                    },
                });
            },
            closeFilterMobile() {
                window.body.removeClass('overflowTrue');
                document.getElementById('header').classList.remove('notVisible');
				this.$store.commit('SetVisibleFilterMobile', false);
            }
            
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
            this.setFilterDataBase();
            setTimeout(() => {
                this.BuildIonRangeSlider();
            }, 300);
            this.$store.watch(
                (state) => state.FilterResetTrigger,
                (newValue) => {
                    if (newValue) {
                        // console.log(this.resetModeTrigger, 'update');
                        let floorFilter = $("#floor-filter").data("ionRangeSlider");
                        let sizeFilter = $("#size-filter").data("ionRangeSlider");
                        let priceFilter = $("#price-filter").data("ionRangeSlider");
                        this.filterData.roomQuantities = [];
                        floorFilter.reset();
                        sizeFilter.reset();
                        priceFilter.reset();
                    }
                }
            )
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/flats-crm/flats-crm-filter.scss';
    @import '@/styles/components/flats-crm/respond/flats-crm-filter-respond.scss';
</style>