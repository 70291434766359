<template>
    <div class="inner-lobbi">
        <div class="container">
            <div class="section-grid-inline">
                <div class="section-grid-info inner-lobbi-info">
                    <div class="title title-black title-small">
                        <h3 v-html="innerContentArr.section_lobbi.title"></h3>
                    </div>
                    <div class="section-grid-images-desc section-grid-images-desc-black" v-html="innerContentArr.section_lobbi.desc_images" v-if="!isMobileG"></div>
                    <div class="desc-html desc-html-black" v-html="innerContentArr.section_lobbi.desc"></div>
                </div>
                <div class="section-images-grid section-images-grid-reverse section-images-grid-lobbi images-grid-opposite-right">
                    <div class="images-items images-items-lobbi">
                        <div class="item" v-for="(item, index) in innerContentArr.section_lobbi.images" :key="index">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
                <!-- MOBILE -->
                <div class="section-grid-images-desc section-grid-images-desc-black" v-html="innerContentArr.section_lobbi.desc_images" v-if="isMobileG"></div>
            </div>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'inner-lobbi',
        data () {
            return {
            }
        },
        computed: mapGetters({
            innerContentArr: 'loadInnerContent',
            isMobileG: 'loadisMobileGlobal',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/inner-content/inner-lobbi.scss';
    @import '@/styles/components/inner-content/respond/inner-lobbi-respond.scss';
</style>