<template>
    <div class="concept-private">
        <div class="container">
            <div class="section-grid">

                <div class="column-container">
                    <div class="col-left">
                        <div class="section-grid-info concept-private-info">
                            <div class="title">
                                <h3 v-html="conceptArr.section_private.title"></h3>
                            </div>
                            <div class="desc-html" v-html="conceptArr.section_private.desc"></div>
                        </div>
                        <div class="section-grid-list-items concept-private-list-items" v-if="!isMobileG">
                            <ul>
                                <li v-for="(item, index) in conceptArr.section_private.list" :key="index">
                                    <p v-html="item.desc"></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="section-images-grid">
                            <div class="images-items">
                                <div class="item" v-for="(item, index) in conceptArr.section_private.images" :key="index">
                                    <img :src="item.img" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- MOBILE END -->
                <div class="section-grid-list-items concept-private-list-items" v-if="isMobileG">
                    <ul>
                        <li v-for="(item, index) in conceptArr.section_private.list" :key="index">
                            <p v-html="item.desc"></p>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'concept-private',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
            isMobileG: 'loadisMobileGlobal',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-private.scss';
    @import '@/styles/components/concept/respond/concept-private-respond.scss';
</style>