<template>
    <div class="concept-arch">
        <div class="container">
            <div class="section-grid-inline">
                <div class="section-grid-info">
                    <div class="title">
                        <h3 v-html="conceptArr.section_arch.title"></h3>
                    </div>
                    <div class="section-grid-images-desc" v-html="conceptArr.section_arch.desc_images" v-if="!isMobileG"></div>
                    <div class="desc-html" v-html="conceptArr.section_arch.desc" :class="{'hide-w-first-mobile': isMobileG}"></div>
                </div>
                <div class="section-images-grid">
                    <div class="images-items">
                        <div class="item" v-for="(item, index) in conceptArr.section_arch.images" :key="index">
                            <img :src="item.img" alt="" class="arch-pic">
                        </div>
                    </div>
                </div>
                <!-- MOBILE -->
                <div class="section-grid-images-desc" v-html="conceptArr.section_arch.desc_images" v-if="isMobileG"></div>
            </div>
        </div>
    </div>
</template>

<script>
    // import svgIcon from '@/components/partials/SvgIcon.vue';
    // import promoForm from '@/components/forms/form.vue'
    import {mapGetters} from 'vuex'
    export default {
        name: 'concept-arch',
        data () {
            return {
            }
        },
        computed: mapGetters({
            conceptArr: 'loadConcept',
            isMobileG: 'loadisMobileGlobal',
        }),
        components: {
            // svgIcon,
            // promoForm,
        },
        methods: {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/concept/concept-arch.scss';
    @import '@/styles/components/concept/respond/concept-arch-respond.scss';
</style>